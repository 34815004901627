/* global particlesJS */
import React, { useLayoutEffect, useRef } from "react";
import _, { forEach } from "lodash";

// CSS
import "../styles/audioVisualiser.css";

function getColorForHeight(barHeight, maxBarHeight) {
    // Define the height thresholds
    const GREEN_THRESHOLD = 11;
    const ORANGE_THRESHOLD = 13.5;

    // Normalize the barHeight to be between 0 and 1 for easier interpolation
    const normalizedHeight = barHeight / maxBarHeight;

    // Calculate the color based on the height
    let r = 0,
        g = 0,
        b = 0;

    if (normalizedHeight <= GREEN_THRESHOLD / maxBarHeight) {
        // Transition from green to orange
        const ratio = normalizedHeight / (GREEN_THRESHOLD / maxBarHeight);
        g = 255; // Full green
        r = Math.round(255 * ratio); // Increase red from 0 to 255
        b = 0; // No blue
    } else if (normalizedHeight <= ORANGE_THRESHOLD / maxBarHeight) {
        // Transition from orange to red
        const ratio =
            (normalizedHeight - GREEN_THRESHOLD / maxBarHeight) /
            (ORANGE_THRESHOLD / maxBarHeight - GREEN_THRESHOLD / maxBarHeight);
        r = 255; // Full red
        g = Math.round(255 - 165 * ratio); // Decrease green from 165 to 0
        b = 0; // No blue
    } else {
        // Full red
        r = 255;
        g = 0;
        b = 0;
    }

    return `rgb(${r}, ${g}, ${b})`;
}

function animateBars(
    analyser,
    canvas,
    canvasCtx,
    dataArray,
    bufferLength,
    statusLightBar
) {
    analyser.getByteFrequencyData(dataArray);

    // Clear the canvas for the next frame
    canvasCtx.clearRect(0, 0, canvas.width, canvas.height);

    // Set canvas background color
    canvasCtx.fillStyle = "transparent";
    canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

    const HEIGHT = canvas.height / 2;
    const barWidth = 5; // Fixed bar width
    let barHeight;
    let x = 0;

    for (var i = 0; i < bufferLength; i++) {
        barHeight = (dataArray[i] / 255) * HEIGHT;

        // Get the color based on the bar height
        let colorString = getColorForHeight(barHeight, HEIGHT);
        canvasCtx.fillStyle = colorString;

        // Draw the bar extending upwards and downwards from the center
        canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);
        statusLightBar.style.borderColor = colorString;

        x += barWidth + 1; // Space between bars
    }
}

export default function AudioVisualiser() {
    const getEl = (id) => document.getElementById(id);
    const addClass = (el, className) => el.classList.add(className);
    const removeClass = (el, className) => el.classList.remove(className);
    const getRand = (min, max) =>
        Math.floor(Math.random() * (max - min + 1) + min);
    const canvasRef = useRef(null);

    const AUDIO_LINK =
        "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1468070/Boost.mp3";

    useLayoutEffect(function () {
        const handleLoad = function () {
            const script = document.createElement("script");
            const statusLightBar =
                document.getElementsByClassName("status-light")[0];
            script.src =
                "https://cdn.jsdelivr.net/npm/particles.js@2.0.0/particles.min.js";
            document.body.appendChild(script);

            const STATE = {
                audio: null,
                songEnded: false,
                usingDefault: false,
                minMag: 0,
                canvas: {
                    height: 28,
                    width: 200,
                },
            };

            const getCSSVariable = (variable) => {
                // Retrieve the CSS variable value and trim any whitespace or extra quotes
                return getComputedStyle(document.documentElement)
                    .getPropertyValue(variable)
                    .replace(/^["']|["']$/g, "") // Remove any leading or trailing quotes
                    .trim();
            };

            const SLOW_PARTICLE_CONFIG = {
                particles: {
                    number: {
                        value: 100,
                    },
                    size: {
                        value: 3,
                        random: true,
                    },
                    opacity: {
                        value: 0.8,
                        random: true,
                    },
                    move: {
                        direction: "right",
                        speed: 4,
                    },
                    line_linked: {
                        enable: false,
                    },
                    color: {
                        value: getCSSVariable("--particles-color"), // Change color to black
                    },
                },
                interactivity: {
                    events: {
                        onhover: {
                            enable: false,
                        },
                    },
                },
            };
            const FAST_PARTICLE_CONFIG = {
                particles: {
                    number: {
                        value: 200,
                    },
                    size: {
                        value: 5,
                        random: true,
                    },
                    opacity: {
                        value: 0.8,
                        random: true,
                    },
                    move: {
                        direction: "right",
                        speed: 20,
                    },
                    line_linked: {
                        enable: false,
                    },
                    color: {
                        value: getCSSVariable("--particles-color"), // Change color to black
                    },
                },
                interactivity: {
                    events: {
                        onhover: {
                            enable: false,
                        },
                    },
                },
            };

            const PLAY_MUSIC = getEl("play-music"),
                STOP_MUSIC = getEl("stop-music"),
                AUDIO_CANVAS = getEl("audio-canvas"),
                PARTICLES_FAST = getEl("particles-fast");

            const initializeCanvas = () => {
                const ctx = AUDIO_CANVAS.getContext("2d");
                ctx.canvas.width = STATE.canvas.width;
                ctx.canvas.height = STATE.canvas.height;
            };

            const createAudio = (mp3) => {
                const url = URL.createObjectURL(mp3),
                    audio = new Audio();
                audio.src = url;
                return audio;
            };

            const isBassABumpin = (dataArray) => {
                if (dataArray[0] === 255 && dataArray[1] === 255) {
                    if (dataArray[2] === 255) {
                        return 2;
                    }
                    return 1;
                }
                return 0;
            };

            const rumbleParticles = (dataArray) => {
                if (isBassABumpin(dataArray)) {
                    removeClass(PARTICLES_FAST, "hidden");
                    setTimeout(() => {
                        addClass(PARTICLES_FAST, "hidden");
                    }, 300);
                }
            };

            const hasSongEnded = (audio) => audio.currentTime >= audio.duration;

            const resetPlayer = () => {
                if (STATE.audio) STATE.audio.currentTime = STATE.audio.duration;
                STATE.songEnded = true;
                removeClass(STOP_MUSIC, "showing");
                setTimeout(() => {
                    cancelAnimationFrame(drawVisual);
                    addClass(PLAY_MUSIC, "transition-in");
                    addClass(STOP_MUSIC, "hidden");
                    removeClass(PLAY_MUSIC, "hidden");

                    setTimeout(() => {
                        removeClass(PLAY_MUSIC, "transition-in");
                        addClass(PLAY_MUSIC, "showing");

                        addClass(PARTICLES_FAST, "initial");
                        addClass(PARTICLES_FAST, "hidden");

                        STATE.songEnded = false;

                        setTimeout(() => {
                            removeClass(PLAY_MUSIC, "showing");
                        }, 100);
                    }, 100);
                }, 100);
            };

            let drawVisual = null;
            const processAudio = (mp3) => {
                let audio = null;
                if (mp3 === "default") {
                    audio = new Audio(AUDIO_LINK);
                    audio.crossOrigin = "anonymous";
                } else {
                    audio = createAudio(mp3);
                }
                STATE.audio = audio;
                audio.addEventListener("loadedmetadata", () => {
                    const audioCtx = new (window.AudioContext ||
                            window.webkitAudioContext)(),
                        audioSrc = audioCtx.createMediaElementSource(audio),
                        analyser = audioCtx.createAnalyser(),
                        canvasCtx = AUDIO_CANVAS.getContext("2d");

                    audioSrc.connect(analyser);
                    audioSrc.connect(audioCtx.destination);
                    analyser.fftSize = 256;
                    audio.play();

                    const bufferLength = analyser.frequencyBinCount,
                        dataArray = new Uint8Array(bufferLength);
                    canvasCtx.clearRect(
                        0,
                        0,
                        STATE.canvas.width,
                        STATE.canvas.height
                    );

                    const draw = () => {
                        const canvas = canvasRef.current;
                        drawVisual = requestAnimationFrame(draw);
                        analyser.getByteFrequencyData(dataArray);
                        canvasCtx.clearRect(
                            0,
                            0,
                            STATE.canvas.width,
                            STATE.canvas.height
                        );
                        canvasCtx.fillStyle = "rgba(0, 0, 0, 0)";
                        canvasCtx.fillRect(
                            0,
                            0,
                            STATE.canvas.width,
                            STATE.canvas.height
                        );

                        rumbleParticles(dataArray);

                        // Drawing the waveform based on frequency data
                        canvasCtx.lineWidth = 1;
                        canvasCtx.strokeStyle = "rgb(255, 255, 255)";
                        canvasCtx.beginPath();

                        const sliceWidth = STATE.canvas.width / bufferLength;
                        let x = 0;

                        for (let i = 0; i < bufferLength; i++) {
                            const v = dataArray[i] / 128.0;
                            const y = (v * STATE.canvas.height) / 2;

                            if (i === 0) {
                                canvasCtx.moveTo(x, y);
                            } else {
                                canvasCtx.lineTo(x, y);
                            }

                            x += sliceWidth;
                        }

                        canvasCtx.lineTo(
                            STATE.canvas.width,
                            STATE.canvas.height / 2
                        );
                        canvasCtx.stroke();

                        animateBars(
                            analyser,
                            AUDIO_CANVAS,
                            canvasCtx,
                            dataArray,
                            bufferLength,
                            statusLightBar
                        );

                        if (hasSongEnded(audio) && !STATE.songEnded) {
                            resetPlayer();
                        }
                    };
                    draw();
                });
            };

            function destroyParticles() {
                if (window.pJSDom && window.pJSDom.length) {
                    window.pJSDom.forEach((p) => p.pJS.fn.vendors.destroypJS());
                    window.pJSDom = [];
                }
            }

            const initializeParticles = () => {
                destroyParticles();

                const config = Object.assign({}, SLOW_PARTICLE_CONFIG);
                const fastConfig = Object.assign({}, FAST_PARTICLE_CONFIG);

                config.particles.color.value =
                    getCSSVariable("--particles-color");
                fastConfig.particles.color.value =
                    getCSSVariable("--particles-color");

                removeClass(PARTICLES_FAST, "hidden");
                particlesJS("particles-fast", fastConfig);
                particlesJS("particles-slow", config);
                addClass(PARTICLES_FAST, "hidden");
            };

            const themeChangeHandler = () => {
                initializeCanvas();
                initializeParticles();
            };

            window.addEventListener("themeChange", themeChangeHandler);

            const startPlayer = (mp3) => {
                addClass(PLAY_MUSIC, "transition-out");
                setTimeout(() => {
                    removeClass(PLAY_MUSIC, "transition-out");
                    removeClass(STOP_MUSIC, "hidden");
                    addClass(PLAY_MUSIC, "hidden");
                }, 100);
                setTimeout(() => {
                    processAudio(mp3);
                }, 200);
                setTimeout(() => {
                    addClass(STOP_MUSIC, "showing");
                }, 100);
            };

            PLAY_MUSIC.onclick = () => {
                startPlayer("default");
            };

            STOP_MUSIC.onclick = () => {
                resetPlayer();
            };

            window.onresize = _.throttle(() => {
                initializeCanvas();
                initializeParticles();
            }, 100);

            window.onload = () => {
                initializeCanvas();
                initializeParticles();

                setInterval(() => {
                    STATE.minMag = getRand(3, 5);
                }, 1000);
            };

            return () => {
                window.removeEventListener("themeChange", themeChangeHandler);
            };
        };

        handleLoad();
        // Cleanup function
        return () => {
            document.removeEventListener("DOMContentLoaded", handleLoad);
        };
    }, []);

    return <canvas id="audio-canvas" ref={canvasRef}></canvas>;
}
