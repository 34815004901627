export const aboutUsImages = {
    title: "Meet the team!",
    images: [
        {
            name: "Bogdan",
            image: "/images/pozaBogdan.webp",
            role: "Sales Manager",
            path: "/bogdan",
        },
        {
            name: "Alex",
            image: "/images/pozaAlex.webp",
            role: "Frontend Developer",
            path: "/alex",
        },
        {
            name: "Alin",
            image: "/images/pozaAlin.webp",
            role: "Lead Developer",
            path: "/alin",
        },
    ],
};
