import React from "react";

// Components
import Projects from "../components/Projects";

// Data
import { projectsData } from "../data/projectsData";

export default function Home() {
    return (
        <>
            <Projects
                isLong={true}
                data={projectsData}
            />
        </>
    )
}