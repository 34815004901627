import React from 'react';

// Styles
import '../styles/navbar.css';


/**
 * A reusable component for the header
 * @returns JSX representation of the header
 */
export default function Navbar({ data }) {
    return (
        <div className='navbar-container'>
            <nav role='navigation'>
                {data.map((item, index) => (
                    <a href={item.link} className="icon-container" key={index} aria-label={item.ariaLabel}>
                        <div className="icon-link">
                            {item.icon}
                            <div className="tooltip">{item.name}</div>
                        </div>
                    </a>
                ))}
            </nav>
        </div>
    );
}
