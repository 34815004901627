import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faSignature } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { faRegistered } from "@fortawesome/free-regular-svg-icons";

export const contactData = {
    title: "Contact",
    titleIcon: (
        <FontAwesomeIcon icon={faAddressCard} className="text-text-color" />
    ),
    sendMessageIcon: (
        <FontAwesomeIcon icon={faPaperPlane} className="text-text-color" />
    ),
    fields: [
        {
            value: "EASY INFO TECH S.R.L.",
            icon: (
                <FontAwesomeIcon
                    icon={faSignature}
                    className="text-text-color"
                />
            ),
        },
        {
            value: "Pitești, Str. Republicii, Nr. 222, județ Argeș",
            icon: (
                <FontAwesomeIcon
                    icon={faLocationDot}
                    className="text-text-color"
                />
            ),
        },
        {
            value: "CUI: 46501824",
            icon: (
                <FontAwesomeIcon icon={faIdCard} className="text-text-color" />
            ),
        },
        {
            value: "J03/1912/2022",
            icon: (
                <FontAwesomeIcon
                    icon={faRegistered}
                    className="text-text-color"
                />
            ),
        },
        {
            value: "contact@easyinfotech.ro",
            icon: <FontAwesomeIcon icon={faAt} className="text-text-color" />,
            href: "mailto:contact@easyinfotech.ro",
        },
        {
            value: "+40 739 659 433",
            icon: (
                <FontAwesomeIcon icon={faPhone} className="text-text-color" />
            ),
            href: "tel:0040739659433",
        },
        {
            value: "Instagram",
            icon: (
                <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-text-color"
                />
            ),
            href: "https://www.instagram.com/easyinfotech.ro/",
        },
        {
            value: "Tiktok",
            icon: (
                <FontAwesomeIcon icon={faTiktok} className="text-text-color" />
            ),
            href: "https://www.tiktok.com/@bogdan.easyinfotech",
        },
    ],
};
