import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import ScrollToTop from "./components/ScrollToTop";
import "process/browser"; // This imports the polyfill for process

// Styles
import "./styles/general.css";

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";

// Data
import { navigationData } from "./data/navigationData";

function App() {
    // Get the initial theme index from localStorage, if it exists, otherwise default to 0
    const initialThemeIndex = parseInt(localStorage.getItem("themeIndex")) || 0;
    const [themeIndex, setThemeIndex] = useState(initialThemeIndex);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        applyTheme(themeIndex);
    }, [themeIndex]);

    const colorThemes = [
        {
            "--background-color": "#000000",
            "--color-icons": "#FDEDDD",
            "--color-component-background": "#0f0f0f",
            "--color-element-background": "#1a1a1a",
            "--particles-color": "#FFFFFF",
            "--color-text": "#FDEDDD",
        },
        {
            "--background-color": "#F4F4F4",
            "--color-icons": "#9B9595",
            "--color-component-background": "#FFFFFF",
            "--color-element-background": "#F4F4F4",
            "--particles-color": "#000000",
            "--color-text": "#000000",
        },
    ];

    const changeColors = () => {
        const newIndex = (themeIndex + 1) % colorThemes.length;
        setThemeIndex(newIndex);
        localStorage.setItem("themeIndex", newIndex); // Save the new index in localStorage
    };

    const applyTheme = (index) => {
        const newTheme = colorThemes[index];

        // Check if the new theme is already applied
        const rootStyle = getComputedStyle(document.documentElement);
        const isThemeApplied = Object.keys(newTheme).every(
            (key) => rootStyle.getPropertyValue(key).trim() === newTheme[key]
        );

        if (isThemeApplied) return; // Skip applying the theme if it is already applied

        // Apply each color variable to the document root
        Object.keys(newTheme).forEach((key) => {
            document.documentElement.style.setProperty(key, newTheme[key]);
        });

        setIsAnimating(true);
        window.dispatchEvent(new Event("themeChange"));
        setTimeout(() => {
            setIsAnimating(false);
        }, 2000); // Match the transition duration
    };

    return (
        <>
            <ScrollToTop />
            <CookieConsent
                location="bottom"
                cookieName="cookieAcceptance"
                expires={30}
                overlay
            >
                This website uses essential cookies and local storage to enhance
                the user experience.
            </CookieConsent>
            <main id="page">
                <div className="contentContainer">
                    {/* Particles needed for background particles needed in AudioVisualiser.js */}
                    <div className="particles" id="particles-slow"></div>
                    <div className="particles" id="particles-fast"></div>
                    <Header
                        changeColors={changeColors}
                        isAnimating={isAnimating}
                    />
                    <Routes>
                        {navigationData.links.map((menuItem, index) => {
                            return (
                                <Route
                                    key={index}
                                    path={menuItem.href}
                                    element={<menuItem.component />}
                                />
                            );
                        })}
                    </Routes>
                    <Footer />
                    <Navbar data={navigationData.navbarData} />
                </div>
            </main>
        </>
    );
}

export default App;
