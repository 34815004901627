import React from "react";

// Components
import ThankYou from "../components/ThankYou";
// Data

export default function Contact() {
    return (
        <>
            <ThankYou />
        </>
    )
}