export const aboutUsData = {
    title: ["Why us ?", "How do we differ ?"],
    shortDescription:
        "At EasyInfoTech, we blend creativity with technology to build not just websites but digital experiences. Our team is passionate about pushing boundaries and delivering solutions that stand out.",
    longDescription: [
        "EasyInfoTech isn’t your typical tech company. We believe in breaking the mold and embracing creativity in everything we do. We craft unique digital experiences tailored to your vision, combining innovative design with cutting-edge technology.",
        "Our team of diverse talents, ranging from developers to designers and strategists, work collaboratively to deliver exceptional results that speak to your audience. We are committed to understanding your needs, pushing boundaries, and creating solutions that truly differentiate you in the digital landscape.",
    ],
    path: "/about-us",
    buttonTitle: "More About Us ->",
    persons: {
        alin: {
            title: ["Hello, I am Alin, Your Digital Architect"],
            //TODO: sa punem fiecare ce consideram ca ar fi potrivit aici ca o scurta descriere
            shortDescription: "",
            longDescription: [
                "With over 5 years of experience, Alin is the cornerstone of our development team. His expertise spans multiple projects and technologies, with a strong focus on e-commerce solutions. Alin’s approach is meticulous and innovative, ensuring every line of code is optimized for performance and scalability. He's passionate about delivering robust, user-friendly digital products that elevate the customer experience.",
            ],
            path: "/alin",
            buttonTitle: "More about Me ->",
        },
        alex: {
            title: ["Hello, I am Alex, Your Interface Craftsman"],
            //TODO: sa punem fiecare ce consideram ca ar fi potrivit aici ca o scurta descriere
            shortDescription: "",
            longDescription: [
                "Alex is our enthusiastic frontend developer who brings fresh perspectives to the table. With a vast experience and a strong passion for coding, Alex specializes in crafting intuitive and responsive interfaces. His dedication to honing his skills and staying up-to-date with the latest web technologies makes him an invaluable part of our creative team.",
            ],
            path: "/alex",
            buttonTitle: "More about Me ->",
        },
        bogdan: {
            title: ["Hello, I am Bogdan, Your Connection Builder"],
            //TODO: sa punem fiecare ce consideram ca ar fi potrivit aici ca o scurta descriere
            shortDescription: "",
            longDescription: [
                "Bogdan is the driving force behind our sales team. With a genuine passion for connecting with clients, he has a knack for understanding their needs and translating them into strategic solutions. His proactive approach and commitment to building lasting relationships make him a key player in expanding EasyInfoTech’s reach.",
            ],
            path: "/bogdan",
            buttonTitle: "More about Me ->",
        },
    },
};
