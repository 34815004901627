import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

/**
 * A reusable component to display text with optional button and long text.
 * @param {bool} isLongText - Boolean which specifies if the component will use the short description or the long one
 * @param {object} data - The data which will be parsed: title, shortDescription, longDescription, buttonText, buttonPath
 * @returns - JSX representation of the component.
 */

export default function TextSection({ isLongText = false, data }) {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    return (
        <section className="text-component component">
            <div className="title-container">
                <h2 className="title">
                    <FontAwesomeIcon icon={faHeart} className="text-text-color" /> Thank You for Reaching Out!
                </h2>
            </div>
            <div className="text-container">
                <p>
                    We appreciate you taking the time to get in touch with us. Your message has been received, and our team will review it as soon as possible.<br /><br />

                    If your inquiry is urgent, please feel free to give us a call at <a href='tel:0040731001'><b>+40 731 001 756</b></a> for a faster response.<br /> Otherwise, we’ll get back to you as soon as possible.<br /><br />

                    Thank you for your patience and for considering us. We look forward to assisting you!<br /><br />

                    Best regards,<br />
                    The EasyInfoTech Team
                </p>
            </div>
        </section>
    );
}
