import React from "react";

// Components
import TextSection from "../components/TextSection";
import InteractableGlobe from "../components/InteractableGlobe";
import MovingTags from "../components/MovingTags";
import Projects from "../components/Projects";
import Stacks from "../components/Stacks";
import ReviewCarousel from "../components/ReviewCarousel";

// Data
import { aboutUsData } from "../data/aboutUsData";
import { skillsData } from "../data/skillsData";
import { projectsData } from "../data/projectsData";
import { stacksData } from "../data/stacksData";
import { reviewsData } from "../data/reviewsData";

export default function Home() {
    return (
        <>
            <TextSection data={aboutUsData} />

            <section className="column-to-row">
                <InteractableGlobe />
                <MovingTags data={skillsData} />
            </section>

            <Projects data={projectsData} />
            <Stacks data={stacksData} />
            {/* <ReviewCarousel
                reviews={reviewsData}
            /> */}
        </>
    );
}
