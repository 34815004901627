import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Styles
import "../styles/stacks.css"

/**
 * A reusable component to display text with optional button and long text.
 * @param {bool} isLong - Boolean which specifies if the component will use the short description or the long one
 * @param {object} data - The data which will be parsed: title, shortDescription, longDescription, buttonText, buttonPath
 * @returns - JSX representation of the component.
 */

export default function Stacks({ isLong = false, data }) {
    const displayedStacks = isLong ? data.stacks : data.stacks.slice(0, 3);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    return (
        <section className="stacks-component component">

            <div className="title-container">
                <div className="title-col-divider">
                    <div className="title-icon-section">
                        {data.titleIcon}
                    </div>
                    <div className="title-section">
                        <h2 className="title">{" " + data.title}</h2>
                    </div>
                </div>
            </div>

            <div className="stacks-container">
                {displayedStacks.map((stack, index) => (
                    <a className="stack-container" key={index} href={stack.href} target="_blank">
                        <div className="title-container">
                            <div className="title-col-divider">
                                <div className="title-icon-section">
                                    <img src={stack.image} alt={stack.name} />
                                </div>
                                <div className="title-section">
                                    <h2 className="title">{" " + stack.name}</h2>
                                    <p className="subTitle text-xs">{stack.skill}</p>
                                </div>
                            </div>
                        </div>

                        <div className="description-container">
                            <div className="vertical-divider">

                            </div>
                            <p className="description">
                                {stack.description}
                            </p>
                        </div>
                    </a>
                ))}
            </div>

            {/* Conditionally render the div only if hasButton is true */}
            {
                !isLong && (
                    <div className="button-container">
                        <div className="text-component-button">
                            <Link to={data.path}>
                                <button>{data.buttonTitle}</button>
                            </Link>
                        </div>
                    </div>
                )
            }
        </section >
    );
}
