import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-regular-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';

export const skillsData = {
    "title": "Skills",
    "icon": <FontAwesomeIcon icon={faCode} className="text-text-color" />,
    "skillsIcon": <FontAwesomeIcon icon={faGem} className="text-icons-color" />,
    "skills": {
        firstRow: [
            "JavaScript",
            "SEO",
            "HTML5/CSS3",
            "React",
        ],
        secondRow: [
            "Responsive Design",
            "Attention to Details",
            "Collaborative",
        ],
        thirdRow: [
            "Problem Solver",
            "Adobe Illustrator",
            "CRM",
        ]
    },
}