import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Styles
import "../styles/projects.css";

/**
 * A reusable component to display text with optional button and long text.
 * @param {bool} isLong - Boolean which specifies if the component will use the short description or the long one
 * @param {object} data - The data which will be parsed: title, titleIcon, buttonTitle, path, projects : name,image,tags,link
 * @returns - JSX representation of the component.
 */

export default function Projects({ isLong = false, data }) {
    const displayedProjects = isLong
        ? data.projects
        : data.projects.slice(0, 2);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    useEffect(() => {
        // Preload images
        displayedProjects.forEach((project) => {
            const img = new Image();
            img.src = project.image;
        });
    }, [displayedProjects]); // Preload images whenever displayedProjects changes

    return (
        <section className="projects-component component">
            <div className="title-container">
                <div className="title-col-divider">
                    <div className="title-icon-section">{data.titleIcon}</div>
                    <div className="title-section">
                        <h2 className="title">{" " + data.title}</h2>
                    </div>
                </div>
            </div>
            <div className="project-grid">
                {displayedProjects.map((project, index) => (
                    <a
                        className="project-container"
                        key={index}
                        href={project.link}
                    >
                        <div className="image-container-projects">
                            <img src={project.image} alt={project.name} />
                        </div>
                        <div className="tags-container">
                            {project.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className="tag">
                                    {tag}
                                </span>
                            ))}
                        </div>
                        <div className="title-container">
                            <h3>{project.name}</h3>
                        </div>
                    </a>
                ))}
            </div>
            {/* Conditionally render the div only if hasButton is true */}
            {!isLong && (
                <div className="button-container">
                    <div className="text-component-button">
                        <Link to={data.path}>
                            <button>{data.buttonTitle}</button>
                        </Link>
                    </div>
                </div>
            )}
        </section>
    );
}
