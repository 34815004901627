import React, { useState, useEffect, useRef } from 'react';
import '../styles/reviews.css'; // Ensure the correct path to your CSS file

const ReviewCarousel = ({ reviews }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalReviews = reviews.length;
    const containerRef = useRef(null);

    const handlePrev = () => {
        setCurrentIndex((currentIndex) => (currentIndex === 0 ? totalReviews - 1 : currentIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((currentIndex) => (currentIndex === totalReviews - 1 ? 0 : currentIndex + 1));
    };

    useEffect(() => {
        const cards = containerRef.current.querySelectorAll('.review-card');
        let maxHeight = 0;
        let maxWidth = 0;

        cards.forEach(card => {
            const cardHeight = card.offsetHeight;
            const cardWidth = card.offsetWidth;

            if (cardHeight > maxHeight) {
                maxHeight = cardHeight;
            }
            if (cardWidth > maxWidth) {
                maxWidth = cardWidth;
            }
        });
        cards.forEach(card => {
            card.style.height = `${maxHeight}px`;
        });

        containerRef.current.style.transform = `translateX(${maxWidth}px)`;
    }, [reviews]);

    return (
        <div className="reviews-component component">
            <div className="reviews-container" ref={containerRef}>
                {(() => {
                    const reviewCards = [];
                    const previousIndex = currentIndex === 0 ? totalReviews - 1 : currentIndex - 1;
                    const nextIndex = currentIndex === totalReviews - 1 ? 0 : currentIndex + 1;
                    const indexesArray = [previousIndex, currentIndex, nextIndex];
                    for (let i = 0; i < 3; i++) {
                        const review = reviews[indexesArray[i]];
                        reviewCards.push(
                            <div className="review-card component" key={indexesArray[i]} id={indexesArray[i]}>
                                <div className="reviewer-info">
                                    <img src={review.image} alt={review.name} className="reviewer-image" />
                                    <div className="reviewer-details">
                                        <div className="reviewer-name">{review.name}</div>
                                        <div className="reviewer-role">{review.role}</div>
                                    </div>
                                </div>
                                <p className="review-text">{review.text}</p>
                            </div>
                        );
                    }
                    return reviewCards;
                })()}
            </div>
            <button className="nav-button prev-button" onClick={handlePrev}>Prev</button>
            <button className="nav-button next-button" onClick={handleNext}>Next</button>
        </div>
    );
};

export default ReviewCarousel;
