import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

export const stacksData = {
    title: "Stacks",
    titleIcon: <FontAwesomeIcon icon={faPencil} className="text-text-color" />,
    buttonTitle: "View Entire Stack",
    path: "/stacks",
    stacks: [
        {
            name: "Wordpress",
            skill: "Web Content Management",
            image: "/images/stacks/wordpress.svg",
            href: "https://wordpress.com",
            description:
                "Powering our clients' websites with the world's most popular content management system, customized to offer flexibility, scalability, and ease of use.",
        },
        {
            name: "React",
            skill: "Web Development",
            image: "/images/stacks/react.svg",
            href: "https://react.dev",
            description:
                "Developing interactive and high-performance user interfaces with React, ensuring fast and seamless user experiences across all platforms.",
        },
        {
            name: "Salesforce",
            skill: "Customer Relationship Management",
            image: "/images/stacks/salesforce.svg",
            href: "https://www.salesforce.com/eu/",
            description:
                "Leveraging Salesforce to streamline customer relationship management and drive effective sales and marketing strategies.",
        },
        {
            name: "HTML5",
            skill: "Structure&Content",
            image: "/images/stacks/html.svg",
            href: "https://en.wikipedia.org/wiki/HTML5",
            description:
                "Building the backbone of modern, responsive websites with HTML5 to ensure compatibility and functionality across all devices.",
        },
        {
            name: "CSS3",
            skill: "Visual Styling",
            image: "/images/stacks/css.svg",
            href: "https://en.wikipedia.org/wiki/CSS",
            description:
                "Crafting visually engaging designs with CSS3 to bring life to our websites, making them not only functional but also aesthetically pleasing.",
        },
        {
            name: "JavaScript",
            skill: "Scripting",
            image: "/images/stacks/javascript.svg",
            href: "https://en.wikipedia.org/wiki/JavaScript",
            description:
                "Using JavaScript to create dynamic, interactive features that enhance user engagement and deliver a smooth browsing experience.",
        },
        {
            name: "Adobe Illustrator",
            skill: "Vector Graphics",
            image: "/images/stacks/ai.svg",
            href: "https://www.adobe.com/products/illustrator.html",
            description:
                "Designing eye-catching graphics and visuals with Adobe Illustrator to make every digital presence more vibrant and memorable.",
        },
        {
            name: "Trello",
            skill: "Project Management",
            image: "/images/stacks/trello.svg",
            href: "https://trello.com",
            description:
                "Keeping our projects on track and our teams aligned using Trello for seamless collaboration and efficient project management.",
        },
        {
            name: "Confluence",
            skill: "Documentation",
            image: "/images/stacks/confluence.svg",
            href: "https://www.atlassian.com/software/confluence",
            description:
                "Documenting our processes and ideas in Confluence to ensure transparent communication and knowledge sharing among our team members.",
        },
        {
            name: "ChatGPT",
            skill: "Content Generation",
            image: "/images/stacks/chatgpt.svg",
            href: "https://openai.com/index/chatgpt/",
            description:
                "Enhancing customer support and content generation using ChatGPT, enabling us to provide quicker responses and more personalized experiences.",
        },
    ],
};
