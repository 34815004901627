import React from "react";

// Components
import Stacks from "../components/Stacks";

// Data
import { stacksData } from "../data/stacksData";

export default function Home() {
    return (
        <>
            <Stacks
                isLong={true}
                data={stacksData}
            />
        </>
    )
}