import React, { useState, useEffect } from 'react';

import '../styles/contact.css';

/**
 * A reusable component to display contact informatikon.
 * @param {object} data - The data which will be parsed
 * @returns - JSX representation of the component.
 */

export default function ContactInfo({ data }) {
    const contactsData = data;
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    return (
        <>
            <section className='component contact-component'>
                <div className='title-container'>
                    <div className='title-col-divider'>
                        <div className='title-icon-section'>
                            {data.titleIcon}
                        </div>
                        <div className='title-section'>
                            <h2 className='title'>{' ' + data.title}</h2>
                        </div>
                    </div>
                </div>
                <div className='contact-grid'>
                    {contactsData.fields.map((contactData, index) => (
                        contactData.href ? (
                            <a className='contact-container' key={index} href={contactData.href}>
                                <div className='title-container'>
                                    <h4>{contactData.icon} {contactData.value}</h4>
                                </div>
                            </a>
                        ) : (
                            <div className='contact-container' key={index}>
                                <div className='title-container'>
                                    <h4>{contactData.icon} {contactData.value}</h4>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </section>
            <section className='component contact-form'>
                <div className='title-container'>
                    <div className='title-col-divider'>
                        <div className='title-icon-section'>
                            {data.sendMessageIcon}
                        </div>
                        <div className='title-section'>
                            <h2 className='title'> Send Us a Message</h2>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00Dd1000001LoNp" method='POST' className='contact-form-container'>
                        <input type='hidden' name="oid" value="00Dd1000001LoNp" />
                        <input type='hidden' name="retURL" value="http://www.easyinfotech.ro/thank-you" />
                        <div className='row'>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    id='first_name'
                                    name='first_name'
                                    placeholder='First Name'
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    id='last_name'
                                    name='last_name'
                                    placeholder='Last Name'
                                    required
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='form-group'>
                                <input
                                    type='email'
                                    id='email'
                                    name='email'
                                    placeholder='Email'
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <input
                                    type='text'
                                    id='phone'
                                    name='phone'
                                    placeholder='Phone'
                                    required
                                />
                            </div>
                        </div>
                        <div className='form-group'>
                            <textarea
                                id='description'
                                name='description'
                                placeholder='Message'
                                required
                            />
                        </div>
                        <button type='submit' className='text-component-button'>Send</button>
                    </form>
                    <p className='text-xs'>
                        The information collected through this form is necessary for processing your request. This data is sent to our CRM and is stored by EasyInfoTech for a limited period, for the purpose of processing. You can exercise your rights to access, rectify, data portability, deletion, objection, and restriction of the processing of your personal data by sending a request via email at: <a href='mailto:contact@easyinfotech.ro' className='text-text-color'>contact@easyinfotech.ro</a>.
                    </p>
                </div>
            </section>
        </>
    );

}