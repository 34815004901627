import React from "react";
import Globe from "../utils/globe.js"
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

// Styles
import "../styles/interactableGlobe.css";

/**
 * A reusable component for displaying an interactable globe
 * @returns JSX representation of an interactable globe
 */
export default function InteractableGlobe() {

    return (
        <div className="interactable-globe-container component">
            <div className="title-container">
                <div className="title-col-divider">
                    <div className="title-icon-section">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="text-text-color" />
                    </div>
                    <div className="title-section">
                        <h2 className="title">Bucharest, Romania</h2>
                    </div>
                </div>
            </div>
            <div className="globe-container">
                <Globe
                    background="transparent"
                    baseColor="#EAE2F8"
                    glowColor="#FDEDDD"
                    markerColor="#FDEDDD"
                    isDraggable={true}
                    dragOptions={{
                        stiffness: 100,
                        damping: 30,
                        mass: 5
                    }}
                    speed={1}
                    phi={0}
                    theta={0.3}
                    dark={1}
                    diffuse={2}
                    mapBrightness={20}
                    maxSamples={20000}
                    markerSize={0.1}
                    markerArray={[{ latitude: 44.439663, longitude: 26.096306 }]}
                    scale={1}
                    alignment="center"
                    maxWidth={900}
                    offset={{ offsetX: 0, offsetY: 0 }}
                />
            </div>
        </div>
    );
}
