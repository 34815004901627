import React from "react";

// Components
import ContactList from "../components/ContactList";
// Data
import { aboutUsData } from "../data/aboutUsData";
import { aboutUsImages } from "../data/aboutUsImage";
import { contactData } from "../data/contactData";

export default function Contact() {
    return (
        <>
            <ContactList
                data={contactData}
            />
        </>
    )
}