import React, { useState, useEffect } from 'react';

// Styles
import "../styles/movingTags.css"

/**
 * A reusable component to display moving text with skills.
 * @param {object} data - The data which will be parsed: title, icon, skills, skills icon
 * @returns - JSX representation of the component.
 */
export default function TextSection({ data }) {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update viewport width in state
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures effect runs only on mount and unmount

    return (
        <section className="skills-component component">
            <div className="title-container">
                <div className="title-col-divider">
                    <div className="title-icon-section">
                        {data.icon}
                    </div>
                    <div className="title-section">
                        <h2 className="title">{" " + data.title}</h2>
                    </div>
                </div>
            </div>
            <div className="moving-text-container">
                <div className="moving-text-separator">
                    <div className="firstRow row">
                        <ul>
                            {data.skills.firstRow.map((item, index) => (
                                <li key={index}>
                                    <div className="skill-container">
                                        <div className="skill-icon">
                                            {data.skillsIcon}
                                        </div>
                                        <div className="skill-text">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <ul aria-hidden="true">
                            {data.skills.firstRow.map((item, index) => (
                                <li key={index}>
                                    <div className="skill-container">
                                        <div className="skill-icon">
                                            {data.skillsIcon}
                                        </div>
                                        <div className="skill-text">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="secondRow row">
                        <ul>
                            {data.skills.secondRow.map((item, index) => (
                                <li key={index}>
                                    <div className="skill-container">
                                        <div className="skill-icon">
                                            {data.skillsIcon}
                                        </div>
                                        <div className="skill-text">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <ul aria-hidden="true">
                            {data.skills.secondRow.map((item, index) => (
                                <li key={index}>
                                    <div className="skill-container">
                                        <div className="skill-icon">
                                            {data.skillsIcon}
                                        </div>
                                        <div className="skill-text">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="thirdRow row">
                        <ul>
                            {data.skills.thirdRow.map((item, index) => (
                                <li key={index}>
                                    <div className="skill-container">
                                        <div className="skill-icon">
                                            {data.skillsIcon}
                                        </div>
                                        <div className="skill-text">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <ul aria-hidden="true">
                            {data.skills.thirdRow.map((item, index) => (
                                <li key={index}>
                                    <div className="skill-container">
                                        <div className="skill-icon">
                                            {data.skillsIcon}
                                        </div>
                                        <div className="skill-text">
                                            {item}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
