// Data for the pages
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import Projects from "../pages/Projects";
import Contact from "../pages/Contact";
import Stacks from "../pages/Stacks";
import ThankYou from "../pages/ThankYou";
import IndividualAboutUs from "../pages/IndividualAboutUs";

// Data for navbar
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

export const navigationData = {
    links: [
        { name: "Home", href: "/", component: Home },
        { name: "AboutUs", href: "/about-us", component: AboutUs },
        { name: "Projects", href: "/projects", component: Projects },
        { name: "Contact", href: "/contact", component: Contact },
        { name: "Stacks", href: "/stacks", component: Stacks },
        { name: "ThankYou", href: "/thank-you", component: ThankYou },
        { name: "Bogdan", href: "/bogdan", component: IndividualAboutUs },
        { name: "Alex", href: "/alex", component: IndividualAboutUs },
        { name: "Alin", href: "/alin", component: IndividualAboutUs },
    ],
    navbarData: [
        {
            name: "Home",
            icon: (
                <FontAwesomeIcon icon={faHouse} className="text-text-color" />
            ),
            link: "/",
            ariaLabel: "Home",
        },
        {
            name: "About",
            icon: <FontAwesomeIcon icon={faUser} className="text-text-color" />,
            link: "/about-us",
            ariaLabel: "About Us",
        },
        {
            name: "Projects",
            icon: (
                <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-text-color"
                />
            ),
            link: "/projects",
            ariaLabel: "Projects",
        },
        {
            name: "Stack",
            icon: (
                <FontAwesomeIcon
                    icon={faLayerGroup}
                    className="text-text-color"
                />
            ),
            link: "/stacks",
            ariaLabel: "Stacks",
        },
        {
            name: "Contact",
            icon: (
                <FontAwesomeIcon
                    icon={faPaperPlane}
                    className="text-text-color"
                />
            ),
            link: "/contact",
            ariaLabel: "Contact",
        },
    ],
};
