import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";

export const projectsData = {
    title: "Projects",
    titleIcon: <FontAwesomeIcon icon={faPencil} className="text-text-color" />,
    buttonTitle: "All Projects",
    path: "/projects",
    projects: [
        {
            name: "Hepi.ro",
            image: "/images/projects/hepi.webp",
            tags: ["Web Design", "UI Design", "Delivery"],
            link: "https://hepi.devzonehub.com",
        },
        {
            name: "Weezkraft.ro",
            image: "/images/projects/weezkraft.webp",
            tags: ["UI/UX", "E-commerce", "Web Design"],
            link: "https://www.weezkraft.com/",
        },
        {
            name: "Dentmarket",
            image: "/images/projects/dentmarket.webp",
            tags: ["Implementation", "Import/Export"],
            link: "https://www.dentmarket.ro/",
        },
        {
            name: "Deas Security",
            image: "/images/projects/deas.webp",
            tags: ["Design", "Import/Export", "Implementation"],
            link: "https://www.securitate-electronica.ro/",
        },
    ],
};
