import React from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop, faBrush } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

// Styles
import "../styles/header.css";

// Images
import logo from "../images/logo.webp";

// Components
import AudioVisualiser from "./AudioVisualiser";

/**
 * A reusable component for the header
 * @returns JSX representation of the header
 */
export default function Header({ changeColors, isAnimating }) {
    return (
        <div className="header-container flex-none h-auto w-full relative">
            <header className="header">
                <a href="/">
                    <div className="logo-heading-container">
                        <div className="logo-container">
                            <img
                                className="logo-image"
                                src={logo}
                                alt="EasyInfoTech"
                            />
                        </div>
                        <div className="heading-container">
                            <h1 className="text-[2rem] font-semibold text-text-color">
                                EasyInfoTech
                            </h1>
                            <p className="text-gray-400">We make IT Easy!</p>
                        </div>
                    </div>
                </a>
                <button className="status" id="play-music">
                    <div className="status-light">
                        <FontAwesomeIcon
                            icon={faPlay}
                            className="text-green-600 h-2 w-2 pr-2"
                        />
                        <p className="text-text-color py-1 px-3 text-sm">
                            <b>Vibe!</b>
                        </p>
                    </div>
                </button>
                <button className="status hidden" id="stop-music">
                    <div className="status-light status-light-red relative">
                        <FontAwesomeIcon
                            icon={faStop}
                            className="text-red-600 h-2 w-2 pr-2"
                        />
                        <AudioVisualiser />
                    </div>
                </button>
                <button
                    className="colorChanger"
                    onClick={changeColors}
                    disabled={isAnimating}
                    alt="Change Colors"
                >
                    <FontAwesomeIcon
                        icon={faBrush}
                        className="h-4 w-4 pr-2 text-text-color"
                    />
                </button>
                <div className="social-icons-container">
                    <a
                        href="https://www.instagram.com/easyinfotech.ro/"
                        className="social-icon-container"
                        aria-label="Instagram"
                    >
                        <div className="text-xl text-text-color hover:text-white">
                            <FontAwesomeIcon
                                icon={faInstagram}
                                className="text-text-color"
                            />
                        </div>
                    </a>
                    <a
                        href="https://www.tiktok.com/@bogdan.easyinfotech"
                        className="social-icon-container"
                        aria-label="TikTok"
                    >
                        <div className="text-xl text-text-color hover:text-white">
                            <FontAwesomeIcon
                                icon={faTiktok}
                                className="text-text-color"
                            />
                        </div>
                    </a>
                </div>
            </header>
        </div>
    );
}
